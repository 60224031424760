import { Box, Link, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { Post } from "../../utils/models";
import { htmlToText } from "html-to-text";
import { PostMedia } from "./PostMedia";
import moment from "moment";

interface PostDisplayProps {
    threadNo: number;
    post: Post;
    references?: Set<number>;
}

export const PostDisplay: React.FC<PostDisplayProps> = ({
    threadNo,
    post,
    references,
}) => {
    return (
        <Paper id={`p${post.no}`}>
            <Box sx={{ m: 2 }}>
                <Stack>
                    <Stack
                        direction="row"
                        sx={{
                            mb: 0.5,
                            columnGap: 2,
                            width: "100%",
                            display: "flex",
                            flex: "1",
                            flexWrap: "wrap",
                        }}
                    >
                        <Typography variant="subtitle1">{post.name}</Typography>
                        <Typography variant="overline">
                            (ID: {post.id})
                        </Typography>
                        <Typography variant="overline">
                            no. {post.no}
                        </Typography>
                        <Typography variant="overline">
                            {moment
                                .unix(post.time)
                                .format("MMMM Do YYYY, h:mm:ss a")}
                        </Typography>
                    </Stack>
                    <Stack
                        direction="row"
                        sx={{
                            mb: 2,
                            columnGap: 1,
                            width: "100%",
                            display: "flex",
                            flex: "1",
                            flexWrap: "wrap",
                        }}
                    >
                        {Array.from(references || []).map((ref) => (
                            <Link
                                variant="caption"
                                key={ref}
                                color="secondary"
                                href={`#p${ref}`}
                            >
                                {`>>${ref}`}
                            </Link>
                        ))}
                    </Stack>
                    <PostMedia threadNo={threadNo} post={post} />
                    {post.com?.split("<br>").map((line, i) => {
                        const testHTML = document.createElement("body");
                        testHTML.innerHTML = line;
                        const testChild = testHTML.firstChild as HTMLElement;

                        const isQuote =
                            testChild?.nodeName === "SPAN" &&
                            testChild?.classList.contains("quote");

                        const linkLocation =
                            testChild?.nodeName === "A"
                                ? (testChild as HTMLAnchorElement).href
                                : null;

                        const emptyLine = line.length < 1;

                        if (emptyLine) {
                            return <br key={i} />;
                        } else if (linkLocation) {
                            return (
                                <Link
                                    variant="body1"
                                    key={i}
                                    color="secondary"
                                    href={linkLocation}
                                >
                                    {htmlToText(line)}
                                </Link>
                            );
                        } else {
                            return (
                                <Typography
                                    variant="body1"
                                    key={i}
                                    color={isQuote ? "primary" : ""}
                                    sx={{
                                        overflowWrap: "break-word",
                                    }}
                                >
                                    {htmlToText(line)}
                                </Typography>
                            );
                        }
                    })}
                </Stack>
            </Box>
        </Paper>
    );
};
