import { Container, Typography } from "@mui/material";
import React, { useState, useCallback } from "react";
import { useThreads } from "../../utils/data";
import { ThreadPreview } from "./ThreadsPreview";
import { Post } from "../../utils/models";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import ScrollToTop from "../SrollToTop";
import { ShowNonStandardButton } from "./ShowNonStandardButton";
import { useInView } from "react-intersection-observer";

export const ThreadsDisplay: React.FC = () => {
    const { threadFirstPosts, isLoading, isError } = useThreads();
    const [showNonStandardPosts, setShowNonStandardPosts] = useState(false);
    const [visibleItems, setVisibleItems] = useState(20);

    const { ref: loadMoreRef } = useInView({
        onChange: (inView) => {
            if (inView) {
                setVisibleItems((prev) =>
                    Math.min(prev + 20, threadFirstPosts?.length || 0)
                );
            }
        },
        threshold: 0.1,
    });

    if (isLoading) return <Typography variant="h5">Loading</Typography>;
    if (isError) return <Typography variant="h5">Error</Typography>;

    const filteredPosts = threadFirstPosts.filter(
        (post) => !post.is_non_standard || showNonStandardPosts
    );

    return (
        <Container
            maxWidth="lg"
            sx={{
                display: "flex",
                justifyContent: "center",
                overflow: "hidden",
            }}
        >
            <ScrollToTop />
            <ShowNonStandardButton
                showNonStandardPosts={showNonStandardPosts}
                setShowNonStandardPosts={setShowNonStandardPosts}
            />
            <Grid2
                container
                spacing={2}
                sx={{ width: "100%", display: "flex", flex: "1" }}
            >
                {filteredPosts
                    .slice(0, visibleItems)
                    .map((threadFirstPost: Post, index) => (
                        <Grid2
                            md={4}
                            sm={6}
                            xs={12}
                            sx={{ display: "flex" }}
                            key={threadFirstPost.no}
                            ref={
                                index === visibleItems - 1
                                    ? loadMoreRef
                                    : undefined
                            }
                        >
                            <ThreadPreview
                                firstPost={threadFirstPost}
                                key={threadFirstPost.no}
                            />
                        </Grid2>
                    ))}
            </Grid2>
        </Container>
    );
};
