import { Fab } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

interface ShowNonStandardButtonProps {
    showNonStandardPosts: boolean;
    setShowNonStandardPosts: (b: (a: boolean) => boolean) => any;
}

export const ShowNonStandardButton: React.FC<ShowNonStandardButtonProps> = ({
    showNonStandardPosts,
    setShowNonStandardPosts,
}) => {
    return (
        <Fab
            onClick={() => setShowNonStandardPosts((state) => !state)}
            sx={{
                position: "fixed",
                right: {
                    xs: "1rem",
                    md: "2rem",
                },
                bottom: {
                    xs: "1rem",
                    md: "2rem",
                },
            }}
            color="primary"
            aria-label="show non standard posts"
        >
            {showNonStandardPosts ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </Fab>
    );
};
