import { Container, Stack, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { useThread } from "../../utils/data";
import { PostDisplay } from "./Post";
import { HomeButton } from "./HomeButton";
import ScrollToTop from "../SrollToTop";

export const ThreadDisplay: React.FC = () => {
    const { threadNo } = useParams();

    const { threadPosts, isLoading, isError } = useThread(
        parseInt(threadNo || "")
    );

    if (isLoading) return <Typography variant="h5">Loading</Typography>;
    if (isError) return <Typography variant="h5">Error</Typography>;

    const postReferences: { [key: number]: Set<number> } = {};

    for (const post of threadPosts.posts) {
        const references = post.com?.matchAll(/#p(\d+)/gm);
        if (!references) continue;
        for (const reference of references) {
            const referenceTo = parseInt(reference[1]);
            if (!(referenceTo in postReferences)) {
                postReferences[referenceTo] = new Set<number>();
            }

            postReferences[referenceTo].add(post.no);
        }
    }

    return (
        <Container maxWidth="lg">
            <ScrollToTop />
            <Stack gap={2}>
                {threadPosts.posts.map((post) => (
                    <PostDisplay
                        threadNo={threadPosts.threads || 0}
                        post={post}
                        key={post.no}
                        references={postReferences[post.no]}
                    />
                ))}
            </Stack>
            <HomeButton />
        </Container>
    );
};
