import { Fab } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom";

export const HomeButton: React.FC = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/`);
    };
    return (
        <Fab
            onClick={handleClick}
            sx={{
                position: "fixed",
                right: {
                    xs: "1rem",
                    md: "2rem",
                },
                bottom: {
                    xs: "1rem",
                    md: "2rem",
                },
            }}
            color="primary"
            aria-label="home"
        >
            <HomeIcon />
        </Fab>
    );
};
