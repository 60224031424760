import { Card, CardHeader, CardMedia, Skeleton } from "@mui/material";
import React from "react";
import { Post } from "../../utils/models";
import moment from "moment";
import { getThreadFileURL } from "../../utils/data";
import mime from "mime";
import { htmlToText } from "html-to-text";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";

interface ThreadPreviewProps {
    loading?: boolean;
    firstPost: Post;
}

export const ThreadPreview: React.FC<ThreadPreviewProps> = ({
    loading,
    firstPost,
}) => {
    const navigate = useNavigate();
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const handleClick = () => {
        navigate(`/thread/${firstPost.no}`);
    };

    return (
        <Card
            ref={ref}
            sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
            }}
            onClick={handleClick}
        >
            <CardHeader
                title={
                    loading ? (
                        <Skeleton
                            animation="wave"
                            height={10}
                            width="80%"
                            style={{ marginBottom: 6 }}
                        />
                    ) : (
                        htmlToText(firstPost.sub || "").toString()
                    )
                }
                subheader={
                    loading ? (
                        <Skeleton animation="wave" height={10} width="40%" />
                    ) : (
                        moment
                            .unix(firstPost.time)
                            .format("MMMM Do YYYY, h:mm:ss a")
                    )
                }
            />
            {loading ? (
                <Skeleton
                    sx={{ height: 190 }}
                    animation="wave"
                    variant="rectangular"
                />
            ) : (
                inView && (
                    <CardMedia
                        loading="lazy"
                        component={
                            mime
                                .getType(firstPost.ext || "")
                                ?.toString()
                                .startsWith("video")
                                ? "video"
                                : "img"
                        }
                        sx={{
                            objectFit: "cover",
                        }}
                        height="200px"
                        width="auto"
                        loop
                        autoPlay
                        muted
                        src={
                            firstPost.tim
                                ? getThreadFileURL(
                                      firstPost.no,
                                      firstPost.tim,
                                      firstPost.filename,
                                      firstPost.ext
                                  )
                                : "x"
                        }
                    />
                )
            )}
        </Card>
    );
};
