import mime from "mime";
import { Post } from "../../utils/models";
import { getThreadFileURL } from "../../utils/data";
import { Box, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { htmlToText } from "html-to-text";

interface PostMediaProps {
    post: Post;
    threadNo: number;
}

export const PostMedia: React.FC<PostMediaProps> = ({ post, threadNo }) => {
    const [maxxed, setMaxxed] = useState(false);

    const handleClick = () => {
        setMaxxed((state) => !state);
    };
    if (!post.tim) {
        return <></>;
    }
    if (mime.getType(post.ext || "")?.startsWith("image")) {
        return (
            <Stack sx={{ mb: 2 }}>
                <Typography variant="caption">
                    {post.filename && htmlToText(post.filename)}
                    {post.ext}
                </Typography>
                <Box
                    component="img"
                    onClick={handleClick}
                    src={getThreadFileURL(
                        threadNo,
                        post.tim,
                        post.filename,
                        post.ext
                    )}
                    alt={post.filename}
                    sx={{
                        display: "block",
                        alignSelf: "start",
                        maxWidth: maxxed
                            ? "100%"
                            : {
                                  xs: "100%",
                                  sm: "80%",
                                  md: "50%",
                              },
                        maxHeight: maxxed ? "" : "300px",
                        width: maxxed ? "100%" : "auto",
                        height: "auto",
                    }}
                />
            </Stack>
        );
    }

    if (mime.getType(post.ext || "")?.startsWith("video")) {
        return (
            <Stack sx={{ mb: 2 }}>
                <Typography variant="caption">
                    {post.filename}
                    {post.ext}
                </Typography>
                <Box
                    onClick={handleClick}
                    component="video"
                    src={getThreadFileURL(
                        threadNo,
                        post.tim,
                        post.filename,
                        post.ext
                    )}
                    muted
                    autoPlay
                    controls
                    loop
                    sx={{
                        display: "block",
                        alignSelf: "start",
                        maxWidth: maxxed
                            ? "100%"
                            : {
                                  xs: "100%",
                                  sm: "80%",
                                  md: "50%",
                              },
                        maxHeight: maxxed ? "" : "300px",
                        width: maxxed ? "100%" : "auto",
                        height: "auto",
                    }}
                />
            </Stack>
        );
    }

    return <></>;
};
